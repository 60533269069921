<template>
  <b-overlay
    :show="checkReviewStore.isPending || reviewsStore.isPending || openHoursStore.isPending"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
    class="min-vh-100"
  >
    <div
      v-if="Object.keys(stylistData).length"
      id="user-profile"
    >
      <stylist-page-header :header-data="stylistData.header" />
      <!-- stylist info  -->
      <section
        id="stylist-info"
        class="pb-2 py-md-4 px-sm-2 px-lg-4"
      >
        <b-container>
          <b-row>
            <!-- left column / description, services -->
            <b-col
              xl="8"
            >
              <stylist-page-review-form
                v-if="checkReviewStore.response.status"
                :business-name="stylistData.header.businessName"
              />
              <stylist-page-review-exist v-if="!checkReviewStore.response.status" />
            </b-col>
            <!-- left column / description, services -->

            <!-- right column / contact, open hours, social media -->
            <b-col
              xl="4"
            >
              <stylist-page-rating :reviews-details="reviewsStore.response" />
              <div class="pl-xl-4">
                <stylist-page-open-hours :open-hours-data="stylistData.openHours" />
              </div>
            </b-col>
          <!--/ right column / contact, open hours, social media -->
          </b-row>
        </b-container>
      </section>
    <!--/ stylist info  -->
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
  BContainer,
} from 'bootstrap-vue'

import { mapActions, mapState } from 'vuex'
import { COMMON_ERROR_404 } from '@/router/routes/routes-path'
import { AlertTriangleIcon } from 'vue-feather-icons'
import { TYPE } from 'vue-toastification'
import StylistPageReviewForm from '@/components/stylist/stylist-page-review/StylistPageReviewForm.vue'
import StylistPageReviewExist from '@/components/stylist/stylist-page-review/StylistPageReviewExist.vue'
import StylistPageHeader from '../stylist-page/StylistPageHeader.vue'
import StylistPageOpenHours from '../stylist-page/StylistPageOpenHours.vue'
import StylistPageRating from '../stylist-page/StylistPageRating.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BContainer,

    StylistPageHeader,
    StylistPageOpenHours,
    StylistPageRating,
    StylistPageReviewForm,
    StylistPageReviewExist,
  },
  data() {
    return {
      stylistData: {
        header: {
          avatar: require('@/assets/images/user-uploads/stylist-profile/avatars/avatar-s-2.jpg'),
          businessName: null,
          coverImg: require('@/assets/images/user-uploads/stylist-profile/backgrounds/timeline.jpg'),
        },
        contact: {
          email: null,
          businessName: null,
          address: null,
          city: null,
          postCode: null,
        },
        openHours: null,
      },
    }
  },
  computed: {
    ...mapState('StylistPageStoreModule', {
      stylistStore: state => state.stylist,
      openHoursStore: state => state.openHours,
      servicesStore: state => state.services,
      reviewsStore: state => state.reviews,
    }),
    ...mapState('StylistPageReviewStoreModule', {
      checkReviewStore: state => state.checkReview,
    }),
  },
  watch: {
    stylistStore: {
      deep: true,
      handler(stylist) {
        if (stylist.error && stylist.error.response.status === 404) {
          window.location.href = `https://${this.$store.getters['app/getDomain']}${COMMON_ERROR_404}`
        }
        if (stylist.response) {
          // Header
          this.stylistData.header.businessName = stylist.response.businessName
          // Contact
          this.stylistData.contact.businessName = stylist.response.businessName
          this.stylistData.contact.email = stylist.response.email
          this.stylistData.contact.address = stylist.response.address
          this.stylistData.contact.city = stylist.response.city
          this.stylistData.contact.postCode = stylist.response.postCode
        }
        if (stylist.error) {
          this.$toast('Error fetching stylist data!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
    openHoursStore: {
      deep: true,
      handler(newOpenHours) {
        if (newOpenHours.response) {
          this.stylistData.openHours = newOpenHours.response
        }
        if (newOpenHours.error) {
          this.$toast('Error fetching open hours!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
    checkReviewStore: {
      deep: true,
      handler(newCheckReview) {
        if (newCheckReview.error && newCheckReview.error.response.status === 404) {
          window.location.href = `https://www.${this.$store.getters['app/getDomain']}${COMMON_ERROR_404}`
        }
        return true
      },
    },
  },
  mounted() {
    this.fetchPublicStylist(this.$store.getters['app/getSubdomain'])
    this.fetchPublicOpenHours()
    this.fetchPublicReviews()
    this.checkPublicAppointmentReview(this.$route.params.appointmentUuid)
  },
  methods: {
    ...mapActions('StylistPageStoreModule', [
      'fetchPublicStylist',
      'fetchPublicOpenHours',
      'fetchPublicReviews',
    ]),
    ...mapActions('StylistPageReviewStoreModule', [
      'checkPublicAppointmentReview',
    ]),
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>
<style lang="scss" scoped>
  .card-body{
    min-width: 300px;
  }
</style>
