<template>
  <tbb-card
    class="mb-4 mb-xl-0"
    title="Write review and add rating"
  >
    <template>
      <div>
        <b-form
          v-if="!saveReviewStore.response"
        >
          <b-form-row
            class="mt-2"
          >
            <b-col
              cols="12"
              lg="6"
              xl
            >
              <span class="d-block d-md-inline-block">
                <span class="d-block d-md-inline-block hearts-margin">
                  <b-form-group
                    label="Rating"
                    label-for="rating"
                  >
                    <b-form-rating
                      id="rating"
                      v-model="rating"
                      icon-empty="heart"
                      icon-half="heart-half"
                      icon-full="heart-fill"
                      inline
                      precision="2"
                      no-border
                      variant="primary"
                      class="no-background font-large-1 m-1"
                    />
                  </b-form-group>
                </span>
              </span>
            </b-col>
          </b-form-row>
          <b-form-row
            class="mt-2"
          >
            <b-col
              cols="12"
              lg="6"
              xl
            >
              <b-form-group
                label="Review"
                label-for="review"
              >
                <b-form-textarea
                  id="review"
                  v-model="message"
                  class="mt-1 mb-1"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row
            class="float-right"
          >
            <b-col
              cols="auto"
            >
              <b-button
                variant="primary"
                :disabled="saveReviewStore.isPending"
                class="mt-2"
                @click="saveReviewForm()"
              >Save
              </b-button>
            </b-col>
          </b-form-row>
        </b-form>
        <div
          v-if="saveReviewStore.response"
          class="text-center"
        >
          <b-col
            cols="12"
            lg="6"
            xl
          >
            <span class="d-block d-md-inline-block mt-2">
              The review has been added successfully
            </span>

            <div class="d-flex justify-content-center m-2">
              <button
                class="btn btn-primary"
                @click="backToStylistPage()"
              >
                Go back to {{ businessName }}'s homepage
              </button>
            </div>
          </b-col>
        </div>
      </div>
    </template>
  </tbb-card>
</template>

<script>
import {
  BFormRating,
  BFormTextarea,
  BForm,
  BFormRow,
  BCol,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { STYLIST_PAGE } from '@/router/routes/routes-names'
import { mapActions, mapState } from 'vuex'
import TbbCard from '../../sites/TbbCard.vue'

export default {
  components: {
    TbbCard,
    BFormRating,
    BFormTextarea,
    BForm,
    BFormRow,
    BFormGroup,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    businessName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rating: 3,
      message: null,
    }
  },
  computed: {
    ...mapState('StylistPageReviewStoreModule', {
      saveReviewStore: state => state.saveReview,
    }),
  },
  methods: {
    ...mapActions('StylistPageReviewStoreModule', [
      'saveReview',
    ]),
    saveReviewForm() {
      this.saveReview({
        appointmentUuid: this.$route.params.appointmentUuid,
        review: {
          rate: this.rating,
          message: this.message,
        },
      })
    },
    backToStylistPage() {
      this.$router.push({ name: STYLIST_PAGE })
    },
  },
}
</script>
<style lang="scss" scoped>
.list-group-item {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0;
  @media (hover: hover) {
    .description {
      transition: transform 0.3s;
    }
    transition: background-color 0.3s;
    &:hover {
      background-color: #fff;

      .description {
        transform: translateX(1.25rem);
      }
    }
  }
  @media (max-width: 767.98px) {
    padding-right: 0;
  }
}

h3 {
  font-size: 2rem;
  font-weight: 700;
}

p {
  margin-top: 0.5rem;
  font-weight: 500;
}

.label {
  font-weight: 400;
  text-transform: uppercase;
  color: #686868;
  font-size: 0.8em;
}

.separator {
  color: #d1b08f;
  margin: 0 0.5rem;
}

.no-background {
  background: transparent !important;
}

.width-80 {
  width: 15% !important;
}
</style>
