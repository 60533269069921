<template>
  <tbb-card
    class="mb-4 mb-xl-0"
    title="Write review and add rating"
  >
    <template>
      <div
        class="text-center"
      >
        <b-col
          cols="12"
          lg="6"
          xl
        >
          <span class="d-block d-md-inline-block mt-2">
            The review has already been added!
          </span>
        </b-col>
      </div>
    </template>
  </tbb-card>
</template>

<script>
import {
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TbbCard from '../../sites/TbbCard.vue'

export default {
  components: {
    TbbCard,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      rating: 3,
      message: null,
    }
  },
}
</script>
<style lang="scss" scoped>
.list-group-item {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0;
  @media (hover: hover) {
    .description {
      transition: transform 0.3s;
    }
    transition: background-color 0.3s;
    &:hover {
      background-color: #fff;

      .description {
        transform: translateX(1.25rem);
      }
    }
  }
  @media (max-width: 767.98px) {
    padding-right: 0;
  }
}

h3 {
  font-size: 2rem;
  font-weight: 700;
}

p {
  margin-top: 0.5rem;
  font-weight: 500;
}

.label {
  font-weight: 400;
  text-transform: uppercase;
  color: #686868;
  font-size: 0.8em;
}

.separator {
  color: #d1b08f;
  margin: 0 0.5rem;
}

.no-background {
  background: transparent !important;
}

.width-80 {
  width: 15% !important;
}
</style>
